.header-status {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 1.5rem;
  background-color:#5D8AA8;
  color: black;
}

.header-status h2 {
  color: grey;
}

.header-logo {
  margin-left: 240px;
  margin-right: auto;
}

.cta {
  margin-left: auto;
  color: white;
}

.cta span {
  margin-right: 20px;
  font-size: 15px;
}

.navigation {
  text-align: center;
}

.navigation a {
  margin: 1rem 0 0 1.2rem;
  color: rgb(58, 58, 58);
  text-decoration: none;
}

.navigation .active {
  text-decoration: underline;
}
